import React from 'react'

const Loader = () => {
  return (
    <>
          <div className="w-screen h-screen flex flex-col items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 md:h-12 md:w-12 w-8 h-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
              <h2 className="text-center text-gray-500 text-xl font-lead mt-4">Loading...</h2>
          </div>
    </>
  )
}

export default Loader