import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseChimney } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'


const Navbar = (props) => {
  return (
    <>
          <nav className="flex items-center justify-between w-full h-16 px-4 md:px-24 sm:px-12">
            <Link to="/" className="flex items-center h-full">
                  <img className="w-8 mr-4" src="https://firebasestorage.googleapis.com/v0/b/nkululekodotio-2b22e.appspot.com/o/blog%2Fadmin%2Flogo.svg?alt=media&token=61be4137-41ff-4b65-bc4a-926c3f7deaac" alt="logo" />
                  <h3 className="text-xl font-normal text-gray-600 uppercase ">Blog</h3>
            </Link>
            <div>
                <Link to="/">
                  <FontAwesomeIcon className="text-xl text-gray-600 duration-150 cursor-pointer hover:text-gray-400" icon={faHouseChimney}/>
                </Link>
            </div>
        </nav>
    </>
  )
}

export default Navbar